import { useFormContext, useController } from 'react-hook-form'
import { Selector, Checkbox, Skeleton } from '@changex/design-system'
import TimeRangeEditor from './time-range-editor'
import AmountEditor from './_basic-details-editor/amount-editor'
import DatesEditor from './_basic-details-editor/dates-editor'
import { usePaymentPlatformAccountsQuery } from 'shared/hooks/use-payment-platform-account-query'
import { TVariant } from 'shared/types/fund-options.type'

const PaymentSystemSelector = () => {
  const { control, unregister } = useFormContext()
  const {
    field: { onChange: onTypeChange, value: typeValue },
  } = useController({
    name: 'options.paymentSystem',
    control,
    rules: { required: true },
  })

  return (
    <Selector
      value={typeValue}
      onChange={(newType) => {
        onTypeChange(newType)
        if (['manual', 'stripe'].includes(newType)) {
          unregister('options.paymentPlatformAccountId')
        }
      }}
      label="Payment system"
      options={[
        { id: 'manual', label: 'Manual' },
        { id: 'stripe', label: 'Stripe' },
        { id: 'wise', label: 'Wise' },
      ]}
    />
  )
}

const PaymentPlatformAccountSelector = () => {
  const paymentPlatformAccountsQuery = usePaymentPlatformAccountsQuery()
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({
    name: 'options.paymentPlatformAccountId',
    control,
    defaultValue: 1,
    rules: { required: true },
  })

  const label = 'Payment platform account'

  if (
    paymentPlatformAccountsQuery.isLoading ||
    !paymentPlatformAccountsQuery.data
  ) {
    return (
      <>
        <div className="block text-sm font-medium text-gray-700">{label}</div>
        <Skeleton />
      </>
    )
  }

  // NOTE: Selectors require a string value but for the field value we need
  //       a number (integer), hence the casting below.
  return (
    <Selector
      value={value.toString()}
      onChange={(value) => {
        onChange(+value)
      }}
      label={label}
      options={paymentPlatformAccountsQuery.data.results.map((value) => {
        return {
          id: value.id.toString(),
          label: value.name,
        }
      })}
    />
  )
}

const GrantTypeSelector = () => {
  const { control, unregister, watch } = useFormContext()
  const {
    field: { onChange: onTypeChange, value: typeValue },
  } = useController({
    name: 'options.type',
    control,
  })
  const variant = watch('options.variant')

  return (
    <Selector
      value={typeValue}
      onChange={(newType) => {
        onTypeChange(newType)
        if (newType === 'open') {
          unregister('options.portfolio')
          unregister('options.variant')
        } else if (newType === 'replication') {
          unregister('options.openGrants')
          if (variant !== TVariant.HIGH_VALUE) {
            unregister('options.notificationDate')
          }
          unregister('options.genericApplicationTerminology')
        } else {
          unregister('options.genericApplicationTerminology')
          unregister('options.variant')
        }
      }}
      label="Grant type"
      options={[
        { id: 'replication', label: 'Replication' },
        { id: 'open', label: 'Open-grant' },
        { id: 'hybrid', label: 'Hybrid' },
      ]}
    />
  )
}

const VariantSelector = () => {
  const { control, watch, unregister } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({
    name: 'options.variant',
    control,
  })
  const type = watch('options.type')

  return (
    <Selector
      value={value}
      onChange={(newVariant) => {
        onChange(newVariant)
        if (newVariant === TVariant.STANDARD && type === 'replication') {
          unregister('options.notificationDate')
        }
      }}
      label="Variant"
      options={[
        {
          id: TVariant.STANDARD,
          label: 'Standard',
        },
        { id: TVariant.HIGH_VALUE, label: 'High-value' },
      ]}
    />
  )
}

const GenericApplicationTerminologyCheckbox = () => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({
    name: 'options.genericApplicationTerminology',
    control,
  })

  return (
    <Checkbox
      label="Use generic application terminology"
      description="If checked, we'll use a simplified landing page with no mention of ideas or projects."
      checked={value}
      onChange={() => {
        onChange(!value)
      }}
    />
  )
}

const BasicDetailsEditor = () => {
  const { watch } = useFormContext()
  const grantType = watch('options.type')
  const paymentSystem = watch('options.paymentSystem')

  return (
    <div className="flex flex-col gap-4">
      <AmountEditor />
      <div className="grid grid-cols-4 gap-4">
        <div>
          <PaymentSystemSelector />
        </div>
        {!['manual', 'stripe'].includes(paymentSystem) && (
          <div>
            <PaymentPlatformAccountSelector />
          </div>
        )}
        <div>
          <GrantTypeSelector />
        </div>
        {grantType === 'replication' && (
          <div>
            <VariantSelector />
          </div>
        )}
      </div>
      {grantType === 'open' && (
        <div className="my-2">
          <GenericApplicationTerminologyCheckbox />
        </div>
      )}
      <DatesEditor />
      <p className="mt-2 text-sm text-gray-500">
        Times & dates are in the time zone selected above.
      </p>
      <TimeRangeEditor />
    </div>
  )
}

export default BasicDetailsEditor
