import { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { DatePicker, TimeZoneSelector } from '@changex/design-system'
import {
  adjustDateTimeForDatePicker,
  adjustDateTimeForSubmission,
} from 'shared/utils/date-helpers'
import { TVariant } from 'shared/types/fund-options.type'

interface DatesEditorProps {}

const DatesEditor: FC<DatesEditorProps> = () => {
  const { control, watch } = useFormContext()
  const grantType = watch('options.type')
  const timeZone = watch('options.timeZone')
  const variant = watch('options.variant')
  const requiresEndDate =
    grantType !== 'replication' || variant === TVariant.HIGH_VALUE

  return (
    <>
      <div className="flex gap-4">
        <Controller
          name="options.timeZone"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TimeZoneSelector value={field.value} onChange={field.onChange} />
          )}
        />
      </div>
      <div className="flex gap-4">
        <Controller
          name="options.startDate"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            // NOTE: This is a hack – our DatePicker component doesn't handle time
            //       zones very well (see: https://github.com/Hacker0x01/react-datepicker/issues/1787),
            //       so in order for the UI to make sense we'll let the user select
            //       absolute times and dates relevant to the selected timezone, this
            //       means however that we need to adjust the values to their correct
            //       UTC values before submission.
            <DatePicker
              value={adjustDateTimeForDatePicker(field.value, timeZone)}
              onChange={(date) =>
                field.onChange(adjustDateTimeForSubmission(date, timeZone))
              }
              label="Start date"
              name={field.name}
              required={true}
              error={!!error}
            />
          )}
        />
        <Controller
          name="options.endDate"
          control={control}
          rules={{ required: requiresEndDate }}
          render={({ field, fieldState: { error } }) => (
            <>
              <DatePicker
                value={adjustDateTimeForDatePicker(field.value, timeZone)}
                onChange={(date) =>
                  field.onChange(adjustDateTimeForSubmission(date, timeZone))
                }
                label="End date"
                name={field.name}
                required={requiresEndDate}
                error={!!error}
              />
            </>
          )}
        />
        {requiresEndDate && (
          <Controller
            name="options.notificationDate"
            control={control}
            rules={{ required: requiresEndDate }}
            render={({ field, fieldState: { error } }) => (
              <>
                <DatePicker
                  value={adjustDateTimeForDatePicker(field.value, timeZone)}
                  onChange={(date) =>
                    field.onChange(adjustDateTimeForSubmission(date, timeZone))
                  }
                  label="Notification date"
                  name={field.name}
                  tooltip="When we'll tell applicants the outcome of their application."
                  required={requiresEndDate}
                  error={!!error}
                />
              </>
            )}
          />
        )}
      </div>
    </>
  )
}

export default DatesEditor
